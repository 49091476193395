<template>
  <canvas id="heartRateChart" width="400" height="300"></canvas>
</template>

<script>
import Chart from 'chart.js'
import dayjs from 'dayjs'
import annotation from 'chartjs-plugin-annotation';

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    heartRateZones: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    const ctx = document.getElementById('heartRateChart')
    const annotationOpacity = 0.1
    const times = []
    const users = []
    const datasets = []
    const annotations = []
    const annotationsConfig = [
      {
        color: `rgba(167, 168, 169, ${annotationOpacity})`,
        min: 0,
        max: this.heartRateZones.veryLight
      },
      {
        color: `rgba(68, 167, 199, ${annotationOpacity})`,
        min: this.heartRateZones.veryLight,
        max: this.heartRateZones.light
      },
      {
        color: `rgba(0, 224, 155, ${annotationOpacity})`,
        min: this.heartRateZones.light,
        max: this.heartRateZones.moderate
      },
      {
        color: `rgba(255, 254, 67, ${annotationOpacity})`,
        min: this.heartRateZones.moderate,
        max: this.heartRateZones.hard
      },
      {
        color: `rgba(255, 100, 100, ${annotationOpacity})`,
        min: this.heartRateZones.hard,
        max: 999
      }
    ]

    const dataTimers = this.data.map(item => {
      return item.timestamp
    })

    const minDate = dataTimers.reduce(function (a, b) { return a < b ? a : b })
    const maxDate = dataTimers.reduce(function (a, b) { return a > b ? a : b })

    for (let time = dayjs(minDate); time.isBefore(dayjs(maxDate)); time = time.add(2, 'seconds')) {
      times.push(time.millisecond(0).toISOString())
    }

    this.data.forEach(item => {
      if (!users.find(user => {
        return user.cognito_id === item.cognito_id
      })) {
        users.push({
          cognito_id: item.cognito_id,
          full_name: item.full_name
        })
      }
    })

    users.forEach(user => {
      const data = []

      times.forEach(time => {
        const item = this.data.find(item => {
          return (item.cognito_id === user.cognito_id && item.timestamp === time) ||
            (item.cognito_id === user.cognito_id && item.timestamp === dayjs(time).add(1, 'seconds').toISOString())
        })
        data.push(item !== undefined ? item.heart_rate : null)
      })

      datasets.push({
        label: '',
        data: data,
        fill: false,
        pointRadius: 0,
        showLine: false,
        borderWidth: 1,
        borderColor: `rgba(167, 168, 169, 0.75)`
      })
    })

    annotationsConfig.forEach(config => {
      annotations.push({
        drawTime: 'beforeDatasetsDraw',
        type: 'box',
        xScaleID: 'x-axis-0',
        yScaleID: 'y-axis-0',
        xMin: 0,
        xMax: times.length,
        yMin: config.min,
        yMax: config.max,
        borderColor: 'rgba(0,0,0,0)',
        backgroundColor: config.color
      })
    })

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: times.map((time, key) => {
          if (key % 2 === 0) {
            return dayjs(time).format('HH:mm:ss')
          } else {
            return ''
          }
        }),
        datasets: datasets
      },
      plugins: [{
        beforeDraw: chart => {
          const ctx = chart.chart.ctx
          ctx.save()
          chart.data.datasets.forEach(dataset => {
            const xAxis = chart.scales['x-axis-0']
            const yAxis = chart.scales['y-axis-0']
            let valueFrom = null
            let valueFromIndex = 0
            let xFrom = null
            let yFrom = null
            ctx.strokeStyle = dataset.borderColor
            dataset.data.forEach((value, index) => {
              if (value != null) {
                const x = xAxis.getPixelForTick(index)
                const y = yAxis.getPixelForValue(value)
                if (valueFrom != null) {
                  ctx.lineWidth = dataset.borderWidth
                  if (index - valueFromIndex > 1) {
                    ctx.setLineDash([8, 8])
                  } else {
                    ctx.setLineDash([])
                  }
                  ctx.beginPath()
                  ctx.moveTo(xFrom, yFrom)
                  ctx.lineTo(x, y)
                  ctx.stroke()
                }
                valueFrom = value
                valueFromIndex = index
                xFrom = x
                yFrom = y
              }
            })
          })
          ctx.restore()
        }
      }],
      options: {
        plugins: [annotation],
        annotation: {
          annotations: annotations
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      }
    })
  }
}
</script>
