<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button v-if="$route.name === 'HistorySummary'"
                           @click="$router.push({name: 'History'})"></ion-back-button>
          <ion-back-button v-else @click="$router.push({name: 'SessionIndex'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.session-summary')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" pull-factor="0.5" pull-min="100" pull-max="200" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div class="avatar-container">
        <avatar :value="data.sessionData.profile_photo_tn" :initials="initials"/>
      </div>
      <ion-item>
        <ion-label v-text="__('interface.name')"></ion-label>
        <ion-note slot="end" v-text="data.sessionData.name"></ion-note>
      </ion-item>
      <ion-item>
        <ion-label v-text="__('interface.duration')"></ion-label>
        <ion-note slot="end" v-text="displayDuration"></ion-note>
      </ion-item>
      <ion-item>
        <ion-label v-text="__('interface.calories')"></ion-label>
        <ion-note slot="end" v-text="Math.round(data.calories)"></ion-note>
      </ion-item>
      <ion-item>
        <ion-label v-text="__('interface.avg-heart-rate')"></ion-label>
        <ion-note slot="end" v-text="Math.round(data.avgHeartRate)"></ion-note>
      </ion-item>
      <ion-item>
        <ion-label v-text="__('interface.max-heart-rate')"></ion-label>
        <ion-note slot="end" v-text="data.maxHeartRate"></ion-note>
      </ion-item>
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>{{ __('interface.heart-rate') }}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <heart-rate-chart v-if="data.heartRate.length > 0 && !loadingState" :data="data.heartRate"
                            :heart-rate-zones="data.heartRateZones"/>
          <p v-else v-text="__('interface.no-data')"></p>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>{{ __('interface.time-in-zones') }}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <time-in-zones-chart v-if="data.timeInZones.length > 0 && !loadingState" :data="data.timeInZones"/>
          <p v-else v-text="__('interface.no-data')"></p>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonPage,
  IonRefresherContent,
  IonRefresher,
  IonCard,
  loadingController
} from "@ionic/vue";
import Avatar from "../../components/Avatar";
import {API} from "@/utils/apiWrapper";
import TimeInZonesChart from "./TimeInZonesChart";
import HeartRateChart from "./HeartRateChart";

export default {
  name: "SessionSummary",
  components: {
    HeartRateChart,
    TimeInZonesChart,
    IonPage,
    Avatar,
    IonRefresherContent,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonRefresher,
    IonCard
  },
  computed: {
    sessionPending() {
      return this.data.sessionPending ?? false
    },
    displayDuration() {
      const minutes = ('0' + Math.floor(this.data.durationInSeconds / 60)).slice(-2)
      const seconds = ('0' + this.data.durationInSeconds % 60).slice(-2)
      let duration = `${minutes}m ${seconds}s`

      if (this.sessionPending) {
        duration += ' ' + this.__('interface.pending')
      }

      return duration
    },
    initials() {
      let initials = ''
      const name = this.data.sessionData.name ?? ''

      name.split(' ').forEach(word => {
        initials += word.substring(0, 1)
      })

      return initials
    }
  },
  data() {
    return {
      data: {
        sessionData: {},
        heartRate: [],
        timeInZones: [],
        durationInSeconds: 0
      },
      loadingState: false
    }
  },
  methods: {
    doRefresh(event) {
      this.loadData().finally(() => {
        event.target.complete()
      })
    },
    loadData() {
      return API.get('TheSpottApi', '/session-summary-customer', {
        queryStringParameters: {
          sessionId: this.$route.params.id,
          contextDate: this.$route.params.contextDate,
        }
      }).then(response => {
        this.data = response.data
      })
    }
  },
  async ionViewWillEnter() {
    const loading = await loadingController.create({
      message: this.__('interface.loading'),
    })
    await loading.present()
    this.loadingState = true

    this.loadData().finally(() => {
      this.loadingState = false
      loading.dismiss()
    })
  }
}
</script>

<style lang="scss">
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  ion-avatar {
    height: 72px;
    width: 72px;

    .blank-logo {
      font-size: 24px;
    }
  }
}
</style>