<template>
  <canvas id="timeInZonesChart" width="400" height="300"></canvas>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    const barOpacity = 0.75
    const ctx = document.getElementById('timeInZonesChart')
    const zones = [
      {
        name: this.__('interface.very-light'),
        value: 'very-light',
        color: `rgba(167, 168, 169, ${barOpacity})`
      },
      {
        name: this.__('interface.light'),
        value: 'light',
        color: `rgba(68, 167, 199, ${barOpacity})`
      },
      {
        name: this.__('interface.moderate'),
        value: 'moderate',
        color: `rgba(0, 224, 155, ${barOpacity})`
      },
      {
        name: this.__('interface.hard'),
        value: 'hard',
        color: `rgba(255, 254, 67, ${barOpacity})`
      },
      {
        name: this.__('interface.max'),
        value: 'max',
        color: `rgba(255, 100, 100, ${barOpacity})`
      }
    ]
    const datasets = []

    zones.forEach(zone => {
      const data = this.data.filter(item => {
        return item.zone === zone.value
      }).map(item => {
        return item.count * 2
      })

      datasets.push({
        label: zone.name,
        data: data,
        backgroundColor: zone.color
      })
    })

    const users = []

    this.data.forEach(item => {
      if (!users.find(user => {
        return user.cognito_id === item.cognito_id
      })) {
        users.push(item)
      }
    })

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [''],
        datasets: datasets
      },
      options: {
        tooltips: {
          callbacks: {
            label: function (item, data) {
              const minutes = ('0' + Math.floor(item.yLabel / 60)).slice(-2)
              const seconds = ('0' + item.yLabel % 60).slice(-2)
              return `${data.datasets[item.datasetIndex].label}: ${minutes}:${seconds}`
            }
          }
        },
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                const minutes = ('0' + Math.floor(value / 60)).slice(-2)
                const seconds = ('0' + value % 60).slice(-2)

                return `${minutes}:${seconds}`
              }
            }
          }]
        }
      }
    })
  }
}
</script>
